<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
					
						
						<div id="app">
						<Dropdown id="inventoryStatus" v-model="product.inventoryStatus" :options="statuses" optionLabel="label" @change="openNew('Email1')" placeholder="Select a Email Template">
							<template #value="slotProps" >
								<div v-if="slotProps.value && slotProps.value.value" >
									<span :class="'product-badge status-' +slotProps.value.value" >{{slotProps.value.label}}</span>
								</div>
							
								<span v-else>
									{{slotProps.placeholder}}
								</span>
							</template>
						</Dropdown>
						</div>
					</template>

					<template v-slot:end>
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="products" v-model:selection="selectedProducts" dataKey="id" :paginator="true" :rows="10"  responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Inbox</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span>
						</div>
					</template>

					<Column field="name" header="Subject" :sortable="true" headerStyle="width:14%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Name</span>
							{{slotProps.data.Subject}}
						</template>
					</Column>
					<Column field="start_time" header="Date Time"  headerStyle="width:14%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Date Time</span>
							{{slotProps.data.datetime}}
						</template>
					</Column>
					
					
					<Column field="emails" header="Emails"  headerStyle="width:34%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Emails Time</span>
							{{slotProps.data.emails.substring(0,20)+"..."}}
						</template>
					</Column>
					
					<Column headerStyle="min-width:14rem;" header="Action">
						<template #body="slotProps">
							<!-- <Button icon="pi pi-eye" class="p-button-rounded  mr-2" @click="editProduct(slotProps.data)" /> -->
							<Button icon="pi pi-user" class="p-button-rounded  mr-2" @click="redirectToResponses(slotProps.data)"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" @click="confirmDeleteProduct(slotProps.data)" />
							
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{height:'80%',width: '70%'}" header="Email Details" :modal="true" class="p-fluid">
					<div class="field">
						<label for="name">Name</label>
						<InputText id="name" v-model.trim="product.name" required="true" autofocus :class="{'p-invalid': submitted && !product.name}" />
						<small class="p-invalid" v-if="submitted && !product.name">Name is required.</small>
					</div>
					<div class="field">
						<label for="emails">Emails</label>
						<Textarea id="emails" v-model.trim="product.emails" required="true" rows="3" cols="20" autofocus :class="{'p-invalid': submitted && !product.emails}" />
						<small class="p-invalid" v-if="submitted && !product.emails">Emails is required.</small>
					</div>


					<div class="formgrid">             
                        <div id = "vue_det">
                             <div v-html = "htmlcontent"></div>
                        </div>
					</div>
					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Send" icon="pi pi-check" class="p-button-text" @click="sendEmail('Email1')" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to delete <b>{{product.doc_id}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = True"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteProduct(product.event_id)" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to delete the selected products?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import ProductService from '../service/ProductService';
import SrateService from "../service/SrateService";

export default {
    el: '#vue_det',
	data() {
		return {
			products: null,
			productDialog: false,
			deleteProductDialog: false,
			deleteProductsDialog: false,
			product: {},
            firstname : "Ria",
            lastname  : "Singh",
            htmlcontent : "",
            TempalteArray:['Th is Template'],
			selectedProducts: null,
			filters: {},
			submitted: false,
			statuses: [
				{label: 'Email1', value: 'instock'},
				{label: 'Email2', value: 'lowstock'},
			]
		}
	},
	productService: null,
	created() {
		this.productService = new ProductService();
		this.srateService = new SrateService();
		this.initFilters();
	},
	mounted() {
		this.srateService.getSrateTemplateData().then((data) => {
                this.products = data.data
                this.loading2 = false;
                this.new_disabled=false;
                console.log(data);
            }); 		
	},
	methods: {
		redirectToResponses(data) {this.$router.push({ path: '/smres/'+data.event_id });},
		formatCurrency(value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			return;
		},
		openNew(id) {
			this.product = {};
			this.submitted = false;
			this.productDialog = true;
            this.srateService.getSrateTemplate(id).then((data) => {
               this.htmlcontent=data.data
               console.log(data);
            });	  
		},
		hideDialog() {
			this.productDialog = false;
			this.submitted = false;
		},
		sendRoster(data){
			this.srateService.sendRosterById(data.event_id).then((res)=>{
					console.log(res);
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Roster Send!', life: 3000});
              
			}).catch(error => {
                                    console.log(error);
                                    this.$toast.add({severity:'error', summary: 'Error', detail: error.message, life: 3000});
                                });
		},
        sendEmail(id){
		this.submitted = true;
		console.log(this.product);
		this.srateService.sendEmailTemp(id,this.product).then((res)=>{
					console.log(res);
            });
		this.$toast.add({severity:'success', summary: 'Successful', detail: 'Email Send!', life: 3000});		
        },
		saveProduct() {
			this.submitted = true;
			console.log(this.product);
			
			if (this.product.name.trim() && 
				this.product.start_time && 
				this.product.end_time && 
				this.product.emails.trim() && 
				this.product.body.trim()
			) {
				this.srateService.save(this.product)
                                .then((res) => {
                                    console.log(res);
                                    this.srateService.getSrateData().then((data) => {
                                        this.products = data.data;
                                        this.$toast.add({severity:'success', summary: 'Successful', detail: 'Record Saved!', life: 3000});
                                    });
                                }).catch(error => {
                                    console.log(error);
                                    this.$toast.add({severity:'error', summary: 'Error', detail: error.message, life: 3000});
                                }).finally(()=> {
                                    this.loading2 = false;
                                    this.new_disabled = false;
                                    this.product = {};
                                    this.productIdEdit=-1;
									this.productDialog = false;
									this.product = {};
                                }); 		
				
			}
		},
		editProduct(product) {
			this.product = {...product};
			this.productDialog = true;
		},
		confirmDeleteProduct(product) {
			this.product = product;
			this.deleteProductDialog = true;
		},
		deleteProduct(event_id) {
			this.srateService.delete(event_id).then((res) => {
			console.log(res);
            });
			// this.products = this.products.filter(val => val.id !== this.product.id);
			// this.deleteProductDialog = false;
			// this.product = {};
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Deleted!', life: 3000});
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.products.length; i++) {
				if (this.products[i].id === id) {
					index = i;
					break;
				}
			}
			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteProductsDialog = true;
		},
		deleteSelectedProducts() {
			// this.products = this.products.filter(val => !this.selectedProducts.includes(val));
			// this.deleteProductsDialog = false;
			// this.selectedProducts = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Not Implemented!', life: 3000});
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
	}
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
